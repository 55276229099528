import React, { useEffect, useMemo, useState } from 'react'
import { apiBackEnd } from 'services/axios'
import { useHistory } from 'react-router-dom'
import { namespaces } from 'i18n/i18n.constants'
import { useAuth } from 'hooks/useAuth'
import useProject from 'repositories/useProjects'
import { TProject } from 'types/types'
import { formatDateToView } from 'utils/helpers'
import { Row, Col, Empty } from 'antd'
import { theme } from 'styles'
import { RiPencilFill } from 'react-icons/ri'

import { useGlobalContext } from 'hooks/useGlobalContext'

import map from 'lodash/map'

import {
  Container,
  Card,
  Content,
  ProjectLogo,
  Avatar,
  Wrapper,
  ProjectLabel,
  Divider,
  Header,
  HeaderTitle,
  Controllers,
  EditProject
} from './styles'
import TextField from 'components/TextField'
import { SearchOutlined } from '@ant-design/icons'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

const Projects = () => {

  const { userData } = useAuth()
  const history = useHistory()
  const project = useProject()
  const { isSignedIn } = useAuth()
  const { t } = useTranslation('namespaces')

  const isAdmin = useMemo(() => Boolean(userData?.role === 'admin'), [userData])

  const global = useGlobalContext()

  const [allProjects, setAllProjects] = useState<any>()
  const [projectsFiltered, setProjectsFiltered] = useState<TProject[]>()
  const [search, setSearch] = useState('')

  const handleProjectSelected = (project:TProject) => {
    global.removeProjectSelected()
    history.push(`/dashboard/${project.id}`)
  }

  const handleEditProject = (event: any, project:TProject) => {
    history.push('/project-controller', { project })
    event?.stopPropagation()
  }

  useEffect(() => {
    const requestConfig = async () => {
      const response = await project.getAllProject()
      if (!response?.data) return

      setAllProjects(response.data)
      setProjectsFiltered(response.data)
    }

    if(isSignedIn){
      requestConfig()
    }
  }, [isSignedIn, apiBackEnd.defaults.baseURL])

  const handleSearch = (value:string, type:string) => {
    setSearch(value)
    if (type === 'click') {
      setSearch('')
    }
  }

  useEffect(() => {
    if(allProjects){
      if(!search){
        setProjectsFiltered(allProjects)
      } else {
        setProjectsFiltered(allProjects.filter((project: any) => project.name.toLowerCase().includes(search.toLowerCase())))
      }
    }
  }, [search, allProjects])

  return(
    <Wrapper>
      <Header>
        <HeaderTitle>
          {t('view all projects', { ns: namespaces.pages.projects })}
        </HeaderTitle>
        <Controllers>
          <div style={{ width: '350px' }}>
            <TextField
              name='search'
              onChange={({ target, type }) => handleSearch(target.value, type)}
              value={search}
              id='input-search-table'
              placeholder={t('search for a project', { ns: namespaces.pages.projects })}
              startIcon={<SearchOutlined />}
              iconColor={theme.colors.YELLOW}
            />
          </div>
          {isAdmin && (
            <Button
              id='btn-new-rotation'
              onClick={() => history.push('/project-controller')}
              type='primary'
              label={t('new project', { ns: namespaces.pages.projects })}
              htmlType='button'
            />
          )}
        </Controllers>
      </Header>
      <Container>
        {!isEmpty(projectsFiltered) ? (
          <Row gutter={[16, 24]} justify='start'>
            {map(projectsFiltered, (project, index) => (
              <Col className='gutter-row' span={12} key={index}>
                <Card onClick={() => handleProjectSelected(project)}>
                  {isAdmin && (
                    <Tooltip title={t('edit project', { ns: namespaces.pages.projects })}>
                      <EditProject onClick={event => handleEditProject(event, project)}>
                        <RiPencilFill />
                      </EditProject>
                    </Tooltip>
                  )}
                  <ProjectLogo>
                    {project?.logo?.url ? (
                      <img src={project?.logo?.url} alt={`logo ${project.name}`} />
                    ) : (
                      <Avatar>{project.name?.charAt(0)}</Avatar>
                    )}
                  </ProjectLogo>
                  <Content>
                    <ProjectLabel>
                      <h3>{t('project', { ns: namespaces.pages.projects })}:</h3>
                      <span>{project.name}</span>
                    </ProjectLabel>
                    <ProjectLabel>
                      <h3>RUC/CNPJ:</h3>
                      <span>{project?.code}</span>
                    </ProjectLabel>
                    <ProjectLabel>
                      <h3>{t('creation date', { ns: namespaces.pages.projects })}:</h3>
                      <span>{formatDateToView(project.created_at)}</span>
                    </ProjectLabel>
                    <Divider />
                    <ProjectLabel>
                      <h3>{t('admin users', { ns: namespaces.pages.projects })}</h3>
                    </ProjectLabel>
                    <ProjectLabel>
                      <h3>{t('name', { ns: namespaces.pages.projects })}:</h3>
                      <span>{project.email}</span>
                    </ProjectLabel>
                  </Content>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Empty description={t('no projects found', { ns: namespaces.pages.projects })} />
        )}
      </Container>
    </Wrapper>

  )
}

export default Projects