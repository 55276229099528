import styled from 'styled-components'
import { MapContainer as LeafletMap, Tooltip } from 'react-leaflet'

export const MapContainer = styled(LeafletMap)`
    .gmnoprint {
        display: none !important;
        z-index: 1 !important;
    }
`

export const ButtonFullScreen = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    top: 80px;

    img {
        width: 100%;
    }
`

export const TooltipCostumer = styled(Tooltip)`
    background: none;
    border: none;
    color: #fff;
    box-shadow: none;
    text-shadow: #fff -1px 0px, #fff 0px 1px, #fff 1px 0px, #fff 0px -1px;

    font-family: Montserrat;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
`

export const TooltipFarm = styled(Tooltip)`
    background: none;
    border: none;
    color: ${({ theme }) => theme.colors.YELLOW};
    box-shadow: none;
    text-shadow: #000 -1px 0px, #000 0px 1px, #000 1px 0px, #000 0px -1px;
    font-family: Montserrat;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    `